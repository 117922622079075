.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
    margin-top: calc(var(--margin-top-content) - 2rem);
}

.mapContainer {
    width: 100%;
    display: flex;
}

.map {
    box-shadow: none;
    border-radius: var(--border-radius-container);
    border: none;
}

.productsContainer {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 992px) {
    .productsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}





/** TEMP **/

.contactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}

.phoneWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    background: #fff;
    padding: 2rem 3rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-in-out;
}

.phoneWrapper:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.phoneLabel {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--color-text-secondary);
    font-weight: 500;
}

.phoneNumber {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    line-height: 1;
}

.phoneNumber:hover {
    color: var(--color-primary-dark);
}

@media (max-width: 768px) {
    .phoneNumber {
        font-size: 1.75rem;
    }
    
    .phoneWrapper {
        padding: 1.25rem 1.75rem;
    }

    .phoneLabel {
        font-size: 0.875rem;
    }

    .contactContainer {
        padding: 2.5rem 0;
    }
}

@media (max-width: 480px) {
    .phoneNumber {
        font-size: 1.5rem;
    }
    
    .phoneWrapper {
        padding: 1rem 1.5rem;
    }

    .phoneLabel {
        font-size: 0.75rem;
    }

    .contactContainer {
        padding: 2rem 0;
    }
}

@media (max-width: 1200px) {
    .contactContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .contactContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .contactContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
